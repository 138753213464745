import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "home": "Home",
      "about": "About Me",
      "projects": "Projects",
      "contact": "Contact",
      "welcome": "Welcome to my Portfolio",
      "download_cv": "Download CV",
      "hello": "Hello!",
      "i_am": "I am",
      "frontend_developer": "Frontend Developer",
      "about_intro": "Hello, I am Matias Gomez Giovagnoli, a passionate frontend developer with a strong interest in creating intuitive and attractive user interfaces.",
      "skills": "SKILLS",
      "technical_skills": "Hard skills",
      "soft_skills": "Soft Skills",
      "frontend_tech": "Frontend Technologies",
      "backend_tech": "Backend Technologies",
      "database_tech": "Databases",
      "other_tools": "Other Tools",
      "comunication_skills": "Comunication skills",
      "comunication_desc": "",
      "team_player": "Team player",
      "team_desc": "",
      "motivation": "Motivation and discipline",
      "motivation_desc": "",
      "professional_experience": "PROFESSIONAL EXPERIENCE",
      "date_3ns": "(July 2023 - July 2024)",
      "3n_1":"Design and develop custom proyects",
      "3n_2":"Proyects documentation",
      "3n_3":"Labelling design",
      "3n_4":"Database design",
      "3n_5":"General websites maintenance",
      "3n_6":"Developmento of new features",
      "3n_7":"NiceLabel Consultant",
      "3n_8":"Client support",
      "3n_9":"Deploys",
      "3n_10":"Agile method: Scrum",
      "walltrip_role": "Frontend Consultan React The Walltrip",
      "walltrip_date": " (January 2024 - April 2024)",
      "wall_1": "Support for Frontend developers",
      "wall_2": "Component refactoring",
      "wall_3": "Support for Frontend developers",
      "featured_projects": "Featured Projects",
      "education_certifications": "EDUCATION AND CERTIFICATIONS",
      "CEAC": "Professional training in multiplatform application development in CEAC (In progress)",
      "loftware_cert": "Loftware Cloud Solutions Expert by Loftware Academy",
      "ibm_cert": "Front-end web Development by IBM Skills Build",
      "coder_cert": "React Js by CoderHouse",
      "download_cert": "Download Certification",
      "lenguajes": "LENGUAGES",
      "ln_spanish": "Spanish: Native",
      "ln_english": "English: Professional",
      "ln_cat": "Catalán: Basic",
      "coopera_desc": "Last professional project",
      "faustina_desc": "Proyect for coderhouse",
      "mega_desc": "Proyect for IBM",
      "portfolio_desc": "This Portfolio",
      "companies_participated": "HIGHLIGHTED COLLABORATIONS",
      "name": "Name",
      "email": "Email",
      "subject": "Subject",
      "message": "Message",
      "send": "Send",
      "email_sent": "Email sent successfully!",
      "email_error": "Failed to send email.",
      "name_required": "Name is required.",
      "email_required": "Email is required.",
      "email_invalid": "Invalid email address.",
      "subject_required": "Subject is required.",
      "message_required": "Message is required.",
      "lets_talk": "Ready to create new awesome proyects?",
      "follow": "Follow me:",
      "email_me": "E-mail",
      "thanks": "Thank you for visit my portfolio.",
      "created": "Portfolio 2024 Created by Matias Gomez Giovagnoli."
    }
  },
  es: {
    translation: {
      "home": "Inicio",
      "about": "Sobre mí",
      "projects": "Proyectos",
      "contact": "Contacto",
      "welcome": "Bienvenido a mi Portafolio",
      "download_cv": "Descargar CV",
      "hello": "Hola!",
      "i_am": "soy",
      "frontend_developer": "Frontend Developer",
      "about_intro": "Hola, soy Matias Gomez Giovagnoli, un apasionado desarrollador frontend con un fuerte interés en crear interfaces de usuario intuitivas y atractivas.",
      "skills": "HABILIDADES",
      "technical_skills": "Tecnologías",
      "soft_skills": "Habilidades blandas",
      "frontend_tech": "Tecnologías Frontend",
      "backend_tech": "Tecnologías Backend",
      "database_tech": "Bases de Datos",
      "other_tools": "Otras Herramientas",
      "comunication_skills": "Habilidades Comunicativas",
      "comunication_desc": "",
      "team_player": "Jugador en equipo",
      "team_desc": "",
      "motivation": "Motivación y disciplina",
      "motivation_desc": "",
      "professional_experience": "EXPERIENCIA PROFESIONAL",
      "date_3ns": "(Julio 2023 - Julio 2024)",
      "3n_1":"Diseño y desarrollo de proyectos a medida",
      "3n_2":"Documentación de proyectos",
      "3n_3":"Diseño de etiquetas",
      "3n_4":"Diseño de base de datos",
      "3n_5":"Mantenimiento general de págimas web",
      "3n_6":"Desarrollo de nuevas funcionalidades",
      "3n_7":"Consultor NiceLabel",
      "3n_8":"Soporte a cliente",
      "3n_9":"Despliegues",
      "3n_10":"Métodos ágil: Scrum",
      "walltrip_role": "Consultor Frontend React The Walltrip",
      "walltrip_date": " (Enero 2024 - Abril 2024)",
      "wall_1": "Apoyo a desarrolladores Frontend",
      "wall_2": "Refactorización de componentes",
      "wall_3": "Desarrollo de funcionalidades",
      "featured_projects": "Proyectos Destacados",
      "education_certifications": "EDUCACÍON Y CERTIFICACIONES",
      "CEAC": "Fomación profesional en Desarrollo de aplicaciones multiplataforma en CEAC (en progreso)",
      "loftware_cert": "Loftware Cloud Solutions Expert en Loftware Academy",
      "ibm_cert": "Front-end web Development en IBM Skills Build",
      "coder_cert": "React Js en CoderHouse",
      "download_cert": "Descargar Certificado",
      "lenguajes": "IDIOMAS",
      "ln_spanish": "Español: Nativo",
      "ln_english": "Inglés: Profesional",
      "ln_cat": "Catalán: Básico",
      "coopera_desc": "Ultimo proyecto profesional",
      "faustina_desc": "Proyecto de CoderHouse",
      "mega_desc": "Proyeco de IBM",
      "portfolio_desc": "Este Portafolio",
      "companies_participated": "COLABORACIONES DESTACADAS",
      "name": "Nombre",
      "email": "Correo Electrónico",
      "subject": "Asunto",
      "message": "Mensaje",
      "send": "Enviar",
      "email_sent": "¡Correo enviado exitosamente!",
      "email_error": "Error al enviar el correo.",
      "name_required": "Cómo me dirijo hacia ti?",
      "email_required": "A que dirección de correo contesto?",
      "email_invalid": "Correo electrónico no válido.",
      "subject_required": "Sobre que hablaremos?",
      "message_required": "Dime lo que piensas.",
      "lets_talk": "Preparado para crear nuevos proyectos geniales?",
      "follow": "Sígueme:",
      "email_me": "E-mail",
      "thanks": "Muchas gracias por visitar mi portfolio",
      "created": "Portfolio 2024 Creado por Matias Gomez Giovagnoli"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;